<script>
export default {
  name: "FloatingActionButton"
}
</script>

<template>
  <div>
  <slot name="action"></slot>
  </div>
</template>

<style scoped>

</style>
